.react-datepicker-wrapper {
    height: 45px;
    width: 100%;
}

.react-datepicker__input-container {
    height: 45px;
}

.form-control {
    height: 45px;
}
